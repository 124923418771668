@import "rsuite/dist/rsuite.css";

:root {
  --color-primary: #eb2f64;
  --color-primary-light: #FF3366;
  --color-primary-dark: #BA265D;
  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;
  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;
  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
  --line: 1px solid var(--color-grey-light-2); }
.noBorder {
  margin-left: auto;
  border:none;
  padding: 1rem 0;
}
.search__input {
  font-family: inherit;
  font-size: 1.5rem;
  color: inherit;
  background-color: var(--color-grey-light-2);
  border: none;
  padding: .7rem 2rem;
  border-radius: 100px;
  width: 40rem;
  transition: all .2s;
  margin-right: -3.25rem; }
.search__input:focus {
  outline: none;
  width: 45rem;
  background-color: var(--color-grey-light-3); }
.search__input::-webkit-input-placeholder {
  font-size: 1.5rem;
  color: var(--color-grey-light-4); }
.search__input:focus + .search__button {
  background-color: var(--color-grey-light-3); }

* {
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  box-sizing: inherit; }

html {
  position: relative;
  box-sizing: border-box;
}
  @media only screen and (max-width: 68.75em) {
    html {
      font-size: 50%; } }


.header {
  font-size: 1.4rem;
  height: 4rem;
  background-color: #308dee;
  border-bottom: var(--line);
  display: flex;
  justify-content: space-between;
  align-items: center; }
::-webkit-scrollbar {
  width: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.user-nav {
  align-self: stretch;
  display: flex;
  align-items: center; }
  .user-nav > * {
    padding: 0 2rem;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center; }
  .user-nav > *:hover {
    background-color: var(--color-grey-light-2); }
  .user-nav__icon-box {
    background: inherit;
    border: none;
    border-radius: 10px;
    color: inherit;
    cursor: pointer;
    margin: 16px;
    position: relative;
    max-width: 150px;
    padding: 0 16px;

  }
  .user-nav__icon-box:active{
    background: #1288ff;
  }
  .user-nav__icon {
    height: 2.25rem;
    width: 2.25rem;
    fill: black; }

.icon {
  height: 1.4rem;
  width: 1.4rem;
  fill: black; }

.icon {
  font-family: OpenSans;
  color: #FFFFFF;

  transition: all ease 0.3s;
}
.icon:active {
  transform: scale(1.6);

}
.title{
  text-decoration: none;
  color: black;
}

.setting-dates{
  font-size: 14px;
}
.MuiCheckbox-root.index	{
  padding: 3px;
}
.MuiTableCell-head.th-higher-lineheight{
  line-height: 30px;
}
.th-row-header{
  font-size:30px;
  row-gap: 5px;
}
.previous_dates-container{
  display:flex;
  flex-direction: column;
  align-items: center;
  gap:20px;
  padding:10px;
}
.leadCounter_dates_latest-container{
  position: relative;
  padding: 10px;
  margin: 10px;
  display: flex;
  justify-content: center;
  gap:25px;
}

.date-wrapper,.leadCounter-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.date-wrapper{
  justify-content: flex-end;
  gap:5px;
}
.leadCounter-container{
  opacity: 0.6;
  justify-content: space-between;
  margin:0px auto 10px auto;
  height: 250px;
  width: 85px;
}
.leadCounter-container:hover{
  opacity: 1;
  cursor: default;
}

.date_leads_chart-wrapper{
  height: 100%;
  width: 100%;
  display:flex;
  justify-content: center;
  align-items: flex-end;
}

.date_leads_chart{
  display: inline-block;
  width: 80%;
  cursor: pointer;
  border-radius: 2px;
  transition: height 0.3s;
}
.lead_date_number{
  width: 100%;
  border-top: 1px solid black;
  text-align: center;
}
.pcr_insert{
  max-width: 50px;
}

.chosen_date_leadInfo-container{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
}
.chosen_date_leadInfo-container > .leadDate_info-container{
  background-color: white;
  padding: 10px 50px ;
  border-radius: 20px;
}
.leadDate_info-container > .exit-wrapper{
  font-size: 30px;
  margin: 30px 0px;
}
.leadDate_info-container > .exit-wrapper>span:hover{
  cursor: pointer;
}

.relative_for_datepicker{
  position: relative;
}
.filerByRotation-container{
  display: flex;
  gap:20px;
  margin:0px 0px 50px;
}
.rotation-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chosen_charts{
  height:600px ;
}




#tooltip #tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color: white;
  color: black;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

#tooltip:hover #tooltiptext {
  visibility: visible;
}

.bolder{
  font-weight: 900;
}

.rs-calendar-header-forward{
  display: none;
}

.chosen_date_leadsInfo-container{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1000000;
}
.leadsDate_info-container{
  max-width:300px;
  max-height: 300px;
  overflow: scroll;
}

.chosen_date_leadsInfo-container > .leadsDate_info-container{
  background-color: green;
  padding: 10px 50px ;
  border-radius: 20px;
}
.leadsDate_info-container > .exit-wrapper{
  font-size: 30px;
  margin: 30px 0px;
}
.leadsDate_info-container > .exit-wrapper>span:hover{
  cursor: pointer;
}