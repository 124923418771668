.App {
  text-align: center;
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
}
table {
  display: block;
  border-collapse: collapse;
  border-spacing: 0;
}
h1{
  border-spacing: 0;
}

*{
  margin: 0;
  padding: 0;
}
html{
  box-sizing: border-box;
}

th,td{
  padding: 2px 2px;


}
th {
background-color: white;
  position: sticky;
  top: 0;
  z-index: 100000;



}




/*.table.table-sm.table-bordered td:hover {
  text-decoration: none;
  border-bottom: 1px dotted #ccc;
  filter: drop-shadow(16px 16px 20px red) invert(75%);
}
*/

.call-timeouts-table .sold-count-header,
.call-timeouts-table .match-count-header {
  max-width: 40px;
  font-size: 12px;
  font-weight: bold;
}

.call-timeouts-table .sold-count-header {
  margin-left: auto;
  position: relative;
  top: 20px;
  right: 15px;
}

.call-timeouts-table .match-count-header {
  margin-right: auto;
}

.call-timeouts-table .match-call-counts-header {
  overflow: hidden;
}

.call-timeouts-table .match-call-counts-header hr {
  transform: rotate(45deg);
  margin-left: -40px;
}

.call-timeouts-table input::-webkit-outer-spin-button,
.call-timeouts-table input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.call-timeouts-table input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

.call-timeouts-table input {
  font-size: 11px;
}

.MuiTableCell-root.hours div {
  min-width: max-content;
  margin: 5px 0;
}

button[disabled] {
  background: #4f4b4b;
  color: #fff;
  cursor: not-allowed;
  opacity: .25;
}

.save-button {
  padding: 10px 30px;
  background: #308dee;
  color: #fff;
  border-radius: 5px;
  transition: all .3s ease-in-out;
}
